import React, { Component } from 'react';
import {Navbar, Nav} from 'react-bootstrap';

import logo_brutal from './images/logo_brutal.svg';
import logo_mobile from './images/logo_mobile.png';

class SiteNavbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navExpanded: false
    }
  }

  setNavExpanded = (expanded) => {
    this.setState({
      navExpanded: expanded,
    })
  };

  setNavClosed = () => {
    this.setState({
      navExpanded: false,
    })
  };

  render() {
    return (
      <div className="fixed-top">
        <Navbar className="bg-success" bg="light" expand="lg" onToggle={this.setNavExpanded} expanded={this.state.navExpanded} >
          <Navbar.Brand href="#home">
            <img className="logo" src={logo_brutal} alt="logo_brutal"/>
            <img className="logo_mobile" src={logo_mobile} alt="logo_mobile"/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto mt-2 mt-lg-0" onSelect={this.setNavClosed}>
              <Nav.Link className="navbar mx-5" href="#nosotros">Nosotros</Nav.Link>
              <Nav.Link className="navbar mx-5" href="#metodologia">Metodología</Nav.Link>
              <Nav.Link className="navbar mx-5" href="#contactanos">Conversemos</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    )
  }
}

export default SiteNavbar;