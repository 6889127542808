import React from 'react';

function Footer() {
  return (
    <div className="fixed-bottom bg-light ">
      <div className="signature ">
          <div className="left">Made with</div>
          <div className="love">
            {/* {<!--?xml version="1.0" encoding="iso-8859-1"?-->} */}
            {/* <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 92 72" enable-background="new 0 0 92 72" xml:space="preserve" aria-labelledby="heartTitle" role="img"> */}
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 92 72" enableBackground="new 0 0 92 72" aria-labelledby="heartTitle" role="img">
              <title id="heartTitle">Full of Love</title>
              <path fill="#ff005d" d="M82.32,7.888c-8.359-7.671-21.91-7.671-30.271,0l-5.676,5.21l-5.678-5.21c-8.357-7.671-21.91-7.671-30.27,0 c-9.404,8.631-9.404,22.624,0,31.255l35.947,32.991L82.32,39.144C91.724,30.512,91.724,16.52,82.32,7.888z"></path>
            </svg>
          </div>
          <div className="right">by Josefa, Maca & Mati</div>
      </div>
      <div className="flex-footer"></div>
    </div>
  )
}

export default Footer;