import React from 'react';
import Fade from 'react-reveal/Fade';

import maca from './images/about_us/maca.png';
import mati from './images/about_us/mati.png';
import linkedin from './images/about_us/linkedin.png';
import behance from './images/about_us/behance.png';
import github from './images/about_us/github.png';


function AboutUs() {
  return (
    <section id="nosotros" className="grey-bg2">
      <div className="grey-bg2 container text-center text-white py-5">
        <h1 className="font-weight-light display-5 text-align-center my-5">Team Brutal</h1>

        <div className="row text-center container">
          <div className="col-xs-12 col-md-5 nosotros-content-right headline">
            <Fade>
              <img src={maca} alt="maca"/>
            </Fade>
            <h5 className="text-center mt-4 font-weight-light">Macarena Rodríguez</h5>
            <p className="text-center font-weight-bold">Director digital</p>
            <p className="text-center">Amante del diseño e implementación centrado en el usuario, interesada en conocer sus necesidades y mejorar su experiencia. Preocupada de investigar e implementar nuevas tendencias.</p>

            <div className="row text-center container">
              <div className="col-xs-12 col-md-11 align-items-center mx-auto ">
                <p>
                  <img src={linkedin} alt="linkedin"/>
                  <a className="green-text  ml-2" href="https://www.linkedin.com/in/macarenarodriguezcorrea/" target="_blank" rel="noopener noreferrer">
                    Mi perfil en LinkedIn
                  </a>
                </p>
                
                <p>
                  <img src={behance} alt="behance"/>
                  <a className="link_1 ml-2" href="https://www.behance.net/macarenaRodriguezC" target="_blank" rel="noopener noreferrer">
                    Mi book en Behance
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-1"></div>

          <div className="col-xs-12 col-md-5 nosotros-content-left pb-5 headline">
            <Fade>
              <img src={mati} alt="mati"/>
            </Fade>
            <h5 className="text-center mt-4 font-weight-light">Matías Pacheco</h5>
            <p className="text-center font-weight-bold">Ingeniero de Software</p>
            <p className="text-center">Ingeniero de software apasionado, que disfruta de todas las etapas de desarrollo. Estoy constantemente estudiando y buscando nuevos proyectos para implementar.</p>
            
            <div className="row text-center container">
              <div className="col-xs-12 col-md-11 align-items-center mx-auto ">
                <p>
                  <img src={linkedin} alt="linkedin"/>
                  <a className="green-text ml-2" href="https://www.linkedin.com/in/mat%C3%ADas-pacheco/" target="_blank" rel="noopener noreferrer">
                    Mi perfil en LinkedIn
                  </a>
                </p>
                <p>
                  <img src={github} alt="github"/>
                  <a className="link_1  ml-2" href="https://github.com/matipacheco" target="_blank" rel="noopener noreferrer">
                    Mi perfil en Github
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}

export default AboutUs;
