import React, {Fragment} from 'react';
import SiteNavbar from './Navbar';
import Header from './Header';
import AboutUs from './AboutUs';
import LetsTalk from './LetsTalk';
import Footer from './Footer';
import Methodology from './Methodology';
import ContactUs from './ContactUs';
import UpArrow from './UpArrow';


function Home() {
  return (
    <Fragment>
      <SiteNavbar/>
      <br/>
      <br/>

      <Header/>
      <AboutUs/>
      <LetsTalk/>
      <Methodology/>
      <ContactUs/>

      <Footer/>
      <UpArrow/>
    </Fragment>
  );
}

export default Home;
