import React, { Fragment } from 'react';
import Fade from 'react-reveal/Fade';

import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import {Spinner, Modal} from 'react-bootstrap';

import error from './images/contact_us/error.png';
import exito from './images/contact_us/exito.png';


function notEmpty(value) {
  return value !== null && value !== undefined && value !== '';
}

class ContactUs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      btnEnabled: false,
      showSuccess: false,
      showError: false,
      validEmail: true
    }

    this.recaptchaRef = React.createRef();
  }

  validForm = (event) => {
    event.preventDefault();

    this.setState({
      [event.target.id]: event.target.value,
    }, () => {
      this.validEmailAddress()
    })
  }

  validEmailAddress = () => {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const validEmail = notEmpty(this.state.email) ? emailRegex.test(this.state.email) : true;
    this.setState({
      validEmail: validEmail
    }, () => {
      this.enableButton()
    })
  }

  enableButton = () => {
    const btnEnabled = (
      notEmpty(this.state.name) &&
      notEmpty(this.state.description) &&
      this.state.validEmail
    );

    this.setState({
      btnEnabled: btnEnabled
    })
  }

  getHeaders = () => {
    return {
      headers: {
        "Access-Control-Allow-Origin": "*"
      }
    }
  }

  getParams = () => {
    return {
      "name-input": this.state.name,
      "email-input": this.state.email,
      "description-input": this.state.description
    }
  }

  sendFormData = () => {
    this.setState({
      loading: true,
      btnEnabled: false,
    }, () => {
      axios.post(
        'https://oxs5vpur9e.execute-api.us-east-1.amazonaws.com/default/brutal-ses-service',
        this.getParams(),
        this.getHeaders()
      )
      .then(response => {
        this.setState({
          formSubmitted: true,
          loading: false,
        })
      })
      .catch(error => {
        this.setState({
          formSubmitted: true,
          showError: true,
          loading: false
        })
      })
      .then(() => {
        setTimeout(() => {
          this.setState({
            formSubmitted: false
          })
        }, 3000)
      })
    })
  }

  onSubmitWithReCAPTCHA = async (event) => {
    event.preventDefault();
    const token = await this.recaptchaRef.current.executeAsync();
    if (token === undefined) {return;}

    this.sendFormData();
  }

  render() {
    return (
      <section id="contactanos" className="bg-degrade4">
        <div className=" bg-degrade4 container py-5">
          <h1 className="font-weight-light display-5 my-5 text-align-center">Conversemos</h1>
          <div className=" mx-4">
            <div className="row  mx-auto">
              <div className="col-xs-12 col-sm-12 col-md-12">
                <Fade>
                  <form id="contact-form" onSubmit={ (event) => this.onSubmitWithReCAPTCHA(event) }>
                    <div className="form-group mb-5 headline">
                      <label htmlFor="name-input">Nombre y apellido *</label>
                      <input name="name-input" type="text" className="form-control" id="name" placeholder="Escribe tu nombre y apellido" onChange={(e) => this.validForm(e)}/>
                    </div>

                    <div className="form-group mb-5 headline">
                      <label htmlFor="email-input">Email *</label>
                      <input name="email-input" type="email" className="form-control" id="email" placeholder="nombre@ejemplo.com" onChange={(e) => this.validForm(e)}/>
                      {
                        !this.state.validEmail &&
                        <small className='text-danger'>Dirección de correo inválida</small>
                      }
                    </div>

                    <div className="form-group mb-5 headline">
                      <label htmlFor="description-input">Escríbenos para darte soluciones *</label>
                      <textarea name="description-input" className="form-control" id="description" rows="3" onChange={(e) => this.validForm(e)}></textarea>
                    </div>

                    <div className="button-group">
                      <ReCAPTCHA
                        ref={this.recaptchaRef}
                        size="invisible"
                        sitekey="6LezSLIZAAAAAMEFcj9Hdm1hgQ728dqz7UGW5-5D"
                        //g={{display: 'none'}}
                      />

                      <div className="mb-5 text-center headline">
                        {
                          this.state.loading ?
                            <Spinner animation="border"/> :
                            <input
                              type="submit"
                              disabled={!this.state.btnEnabled}
                              className="btn btn-outline-dark btn-lg btn-contact"
                              value="Hablemos de tu proyecto"
                            />
                        }
                      </div>
                    </div>

                  </form>
                </Fade>
              </div>
            </div>

            <Modal show={this.state.formSubmitted}>
              <div className='text-center'>
                <Modal.Body>
                  {
                    this.state.showError  ?
                    <Fragment>
                      <img className="message-sent-icon my-5" src={error} alt="error"/>
                      <p className="modal-paragraph  h4 font-weight-light mb-5">
                        <b className="red-text h2">¡Oops!</b> Inténtalo más tarde o envíamos un correo a <b className="green-bold-text">contacto@brutalrocks.com</b>
                      </p>
                    </Fragment> :

                    <Fragment>
                      <img className="message-sent-icon my-5" src={exito} alt="exito"/>
                      <p className="modal-paragraph h4 font-weight-light  mb-5">
                        <b className="success-text h2">¡Yeah!</b> Recibimos tu correo, nos contactaremos contigo a la brevedad
                      </p>
                    </Fragment>
                  }
                </Modal.Body>
              </div>
            </Modal>

          </div>
        </div>
      </section>
    )
  }
}

export default ContactUs;