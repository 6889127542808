import React from 'react';
import maca_mati from './images/header/maca_mati.png';
import desarrollo_medida from './images/header/desarrollo_medida.png';
import usuario from './images/header/usuario.png';
import design from './images/header/design.png';

function Header() {
  return (
    <section id="header" className="bg-degrade4 pt-4">
      <div className="bg-degrade4 container py-5">
        <div className="  text-center">

          <div className="row text-center py-4">
            <div className=" col-xs-11 col-sm-5 col-md-5">
              <div className="header-content-right  text-center">
                <h1 className="display-5 font-weight-light">En Brutal nos apasiona construir experiencias digitales desde cero. </h1>
                <p className="mt-3 texto_bajada">Resolvemos necesidades concretas de nuestros clientes con creativas soluciones digitales.</p>
                <a className="btn btn-outline-dark" href="#contactanos">Hablemos de tu proyecto con un rico café</a>
              </div>
            </div>
            
            <div className=" col-xs-12 col-sm-6 col-md-6">
              <div className="header-content-left headline">
                <img className="notebook animate fadeInUp delay-2s" src={maca_mati} alt="maca_mati"/>
              </div>
            </div>
          </div>

          <div className="row text-center  mx-auto mt-5">
            <div className="col-xs-12 col-sm-12 col-md-4">
              <img className="my-2 " src={desarrollo_medida} alt="desarrollo_medida"/>
              <p className="texto_bajada">Desarrollo a tu medida</p>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-3">
              <img className="my-2" src={usuario} alt='usuario'/>
              <p className="texto_bajada">Centrado en el usuario</p>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-4">
            <img className="my-2" src={design} alt='design'/>
              <p className="texto_bajada">Diseños intuitivos</p>
            </div>
          </div>

        </div>
      </div>
    </section>
  )
}

export default Header;
