import React, {useEffect} from 'react';
import $ from 'jquery';
import chevron from './images/chevron-up.svg';

function UpArrow() {
  useEffect(() => {
    $('.go-up').click(function(){
      $('body, html').animate({
        scrollTop: '0px'
      }, 300);
    });

    $(window).scroll(function(){
      if( $(this).scrollTop() > 0 ){
        $('.go-up').slideDown(300);
      } else {
        $('.go-up').slideUp(300);
      }
    });
  })

  return (
    <span className="go-up">
      <img className="go-up-arrow"src={chevron} alt="up-arrow"/>
    </span>
  )
}

export default UpArrow;
