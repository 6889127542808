import React from 'react';
import Fade from 'react-reveal/Fade';

import analisis from './images/methodology/analisis.png';
import desarrollo from './images/methodology/desarrollo.png';
import estrategia from './images/methodology/estrategia.png';
import wireframes from './images/methodology/wireframes.png';

function Methodology() {
  return (
    <section id="metodologia" className="grey-bg2">
      <div className="  grey-bg2 container  py-5 text-white">
        <h1 className="font-weight-light display-5 text-align-center my-5">Nuestra metodología</h1>
        {/* {<h5 className="font-weight-light text-center mb-5">Para conectar efectivamente a tu empresa con las personas necesitamos orden:</h5>} */}
        <div className=" text-center">

          <div className="row  text-center  ">
            <div className="col-md-4 mx-4 mb-5 headline">
              <Fade>
                <img src={analisis} alt='analisis' className="mb-4"/>
              </Fade>
              <p className=" h4 green-text mb-3 font-weight-light">1. Análisis</p>
              <p>Analizamos tu competencia, tu actual situación online y los usuarios a quienes te diriges.</p>
            </div>

            <div className="col-md-2"> </div>

            <div className="col-md-4 mx-4 mb-5 headline">
              <Fade>
                <img src={estrategia} alt='estrategia' className=" mb-4"/>
              </Fade>
              <p className="  h4 green-text mb-3 font-weight-light">2. Estrategia</p>
              <p>Definimos la estrategia para que logres tus objetivos, a través de la mejor experiencia de usuario.</p>
            </div>
          </div>

          <div className="row text-center  mb-5">
            <div className="col-md-4 mx-4 mb-5 headline">
              <Fade>
                <img src={wireframes} alt='wireframes' className="mb-4"/>
              </Fade>
              <p className="  h4 green-text mb-3 font-weight-light">3. Wireframes</p>
              <p>Creamos prototipos de las páginas, con la estructura de tu sitio web y la navegación entre ellas.</p>
            </div>

            <div className="col-md-2 "> </div>

            <div className="col-md-4 mx-4 mb-5 headline">
              <Fade>
                <img src={desarrollo} alt='desarrollo' className=" mb-4"/>
              </Fade>
              <p className="  h4 green-text mb-3 font-weight-light">4. Desarrollo</p>
              <p>Desarrollamos e implementamos la estrategia definida con la tecnología que se requiera.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Methodology;