import React from 'react';
import Fade from 'react-reveal/Fade';
import perro_negro from './images/lets_talk/perro_negro.png';
import gato_negro from './images/lets_talk/gato_negro.png';

function LetsTalk() {
  return (
    <section id="conversemos" className="bg-degrade4">
      <div className="bg-degrade4 container text-center py-4 ">
        <div className="text-center">
          <h1 className="font-weight-light display-5 pb-4 text-align-center my-2">¿Tienes un proyecto para nosotros? </h1>
          <div className="row ">
            <div className="col-xs-10 col-sm-10 col-md-12 col-lg-11">
              <div className="pb-2 headline">
                <a className="btn btn-outline-dark" href="#contactanos">Conversemos, nos encantaría ayudarte</a>
                <div className="row container text-center pt-4">
                  <div className="col-xs-10 col-sm-10 col-md-12 col-lg-12 headline">
                    <Fade>
                      <img src={perro_negro} alt='perro_negro'/>
                      <img src={gato_negro} alt='gato_negro'/>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LetsTalk;